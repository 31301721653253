.requisition-progress {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: #849397;
}

.requisition-progress > li.is-rejected:before {
  color: white;
  background: red;
}

.requisition-progress > li {
  position: relative;
  display: table-cell;
  text-align: center;
  font-size: 0.8em;
}
.requisition-progress > li:before {
  content: attr(data-step);
  display: block;
  margin: 0 auto;
  background: #dfe3e4;
  width: 3em;
  height: 3em;
  text-align: center;
  margin-bottom: 0.25em;
  line-height: 3em;
  border-radius: 100%;
  position: relative;
  z-index: 1000;
}
.requisition-progress > li:after {
  content: '';
  position: absolute;
  display: block;
  background: #b8b8b8;
  width: 100%;
  height: 0.5em;
  top: 1.25em;
  left: 50%;
  margin-left: 1.5em\9;
  z-index: 400;
}
.requisition-progress > li:last-child:after {
  display: none;
}
.requisition-progress > li.is-pending {
  color: #f29f05;
}

/* 
.requisition-progress > li.is-pending:after
This will add color to horizontal line
*/
.requisition-progress > li.is-pending:before {
  color: #fff;
  background: #f2cb57;
}
.requisition-progress > li.is-active {
  color: #2ecc71;
}
.requisition-progress > li.is-active:before {
  color: #fff;
  background: #34db7a;
}

/**
 * Needed for IE8
 */
.progress__last:after {
  display: none !important;
}
