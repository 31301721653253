.spanner {
  position: absolute;
  left: 0;
  background: #2a2a2a55;
  width: 100%;
  height: 100%;
  top: 50%;
  display: block;
  text-align: center;
  transform: translateY(-50%);
  z-index: 1000;
  visibility: hidden;
}

.overlay {
  visibility: hidden;
}

.show {
  visibility: visible;
}

.spanner,
.overlay {
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.spanner.show,
.overlay.show {
  opacity: 1;
}
