@font-face {
  font-family: Poppins-Medium;
  src: url('./Poppins-Medium.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: rgb(248, 248, 248);
}

.my-container {
  display: flex;
  max-width: 800px;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;
  font-family: Poppins-Medium;
  color: #333;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-heading h1 {
  margin-top: 5%;
  font-size: 3em;
}

.main-heading p {
  font-size: 1.2em;
}

.page-number p {
  background: #e7519e;
  color: #fff;
  padding: 7px 16px;
  border-radius: 30px;
}

main {
  width: 80%;
  margin: 80px auto 0px auto;
}

.custom-input-group {
  margin: 25px 0;
}

.custom-input-group label {
  display: block;
  color: #5a5252;
  font-size: 18px;
  line-height: 1.2;
  padding-left: 2px;
  margin-bottom: 10px;
}

.custom-input-group p {
  width: 100%;
}

.custom-input-group input {
  width: 100%;
  color: rgb(51, 28, 58);
  font-weight: normal;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 3px 10px 25px rgba(161, 161, 161, 0.2),
    0px 0px 10px rgba(204, 204, 204, 0.2);
}

.custom-input-group button {
  width: 14%;
  padding: 12px 0px;
  color: #fff;
  font-size: 13px;
  margin-left: -14%;
  background: #e7519e;
  border-radius: 0 10px 10px 0;
}

.custom-input-group button:hover {
  color: #ffebf5;
}

.custom-input-group button:disabled {
  color: rgba(255, 235, 245, 0.5);
  cursor: not-allowed;
}

.resend button {
  border: none;
  width: 10%;
  border-radius: 0;
  background: #fff;
  border-radius: 12px;
  color: #333;
  margin: 0 5px;
}

.resend button:hover {
  color: #444;
  background: #f8f8f8;
}

.resend button:disabled {
  color: #333;
  background: rgba(248, 248, 248, 0.5);
}

.subdomain button {
  background: #f8f8f8;
  width: 40%;
  font-size: 1em;
  margin-left: -40%;
  color: #333;
}

.subdomain button:hover {
  color: #333;
}

.text-follows {
  font-size: 0.8em;
  color: rgb(148, 148, 148);
  padding-top: 10px;
  margin-bottom: 0;
}

.small-text-faded {
  font-size: 15px;
  color: rgb(102, 102, 102);
  margin-left: 15px;
}

main .next-button {
  padding: 5% 10%;
  display: flex;
}

main .section-heading p {
  text-transform: capitalize;
  color: #572e43;
  font-size: 1.5rem;
}

main .next-button button {
  width: 100%;
  background-image: linear-gradient(#e7519e, #c87ced);
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.2), 0px 0px 25px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  border: none;
  padding: 10px 60px;
  border-radius: 30px;
}

main .next-button button:hover {
  background-image: linear-gradient(#c87ced, #e7519e);
  transition-delay: 10s;
}

main .next-button button:disabled {
  background-image: linear-gradient(
    rgba(200, 124, 237, 0.8),
    rgba(231, 81, 158, 0.8)
  );
  cursor: not-allowed;
  transition-delay: 10s;
}

main .back-button {
  margin-right: 8px;
}

main .back-button button {
  background: linear-gradient(rgb(67, 98, 122), rgb(78, 83, 146));
  border: none;
  border-radius: 30px;
  padding: 8px 50px;
  cursor: pointer;
  color: #fff;
  font-size: 1.3rem;
}

main .back-button button:hover {
  background: linear-gradient(rgb(78, 83, 146), rgb(67, 98, 122));
}

.center-image img {
  max-width: 70%;
  display: flex;
  margin: auto;
  margin-top: 80px;
}

@media (max-width: 680px) {
  .header {
    padding: 0 20px;
    text-align: center;
    display: inline;
  }

  .page-number p {
    width: 30%;
    margin: auto;
  }

  main {
    margin: 20px auto 0px auto;
  }

  main .next-button {
    padding: 10px 0 30px 0;
  }

  main .next-button button {
    padding: 10px 25px;
  }

  .custom-input-group button {
    width: 27%;
    font-size: 13px;
    margin-left: -27%;
  }

  .resend button {
    width: 15%;
    padding: 0 1px;
    margin: 0 1%;
  }
}
