.mobile-float {
  text-align: right;
}

.floating-row {
  float: right;
  text-align: right;
  width: 100%;
}

@media (max-width: 800px) {
  .mobile-float {
    text-align: start;
  }

  .floating-row {
    float: left;
    text-align: left;
  }
}
