.no-focus input {
  outline: none;
  outline-width: 0px;
}

.no-focus input:focus {
  outline: none;
}

.ek-line {
  display: inline;
}
