.iccoon {
  cursor: pointer;
  transition: transform 0.2s;
}

.iccoon:hover {
  transform: scale(1.5);
}

.expand-on-hover {
  cursor: pointer;
  transition: transform 0.2s;
}

.expand-on-hover:hover {
  transform: scale(1.01);
}
